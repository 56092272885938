import type {
  CreatorGigsResponse,
  CreatorGig,
  CreatorGigMetricsMap,
} from 'account/types/creator-gig'

export default async function (query: string) {
  const { $notify } = useNuxtApp()
  const isLoaded = ref(false)
  const gigs = ref<CreatorGig[]>([])
  const metrics = ref<CreatorGigMetricsMap>({})

  const fetchGigs = async () => {
    try {
      const { data, error } = await useApi<CreatorGigsResponse>('/me/gigs', {
        params: {
          where: query,
        },
      })
      if (error.value) {
        throw new Error(error.value.message)
      }
      if (data.value) {
        gigs.value = data.value.results
        metrics.value = data.value.metrics
      }
    } catch (error) {
      $notify({ severity: 'error', text: 'Error getting creator Collabs' })
    } finally {
      isLoaded.value = true
    }
  }
  await fetchGigs()
  return {
    isLoaded,
    gigs,
    metrics,
    fetchGigs,
  }
}
